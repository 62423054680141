import React, { FormEvent, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { axiosClient, ErrorResponse, parseError } from 'src/util/dashboardAxios';

function SuggestionForm(): JSX.Element {
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isSuccessOpen, setSuccessOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [word, setWord] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [syllables, setSyllables] = useState<(number | null)[]>([null]);

  async function createSuggestion() {
    try {
      setLoading(true);
      setError(null);
      setSuccess(null);
      setSuccessOpen(false);
      // Check for existing vote
      const syllablesString = syllables.filter((x) => x !== null).join(',');
      const formattedWord = word.toLocaleLowerCase();
      await axiosClient.post('/api/suggestions', {
        data: {
          word: formattedWord,
          description,
          syllables: syllablesString,
        },
      });

      // Clear form
      setWord('');
      setDescription('');
      setSyllables([null]);
      setSuccess(`Successfully added a suggestion for '${word}'.`);
      setSuccessOpen(true);
    } catch (responseError) {
      setError(parseError(responseError));
      setSuccess(null);
      setSuccessOpen(false);
    } finally {
      setLoading(false);
    }
  }

  if (isLoading) {
    return (
      <Paper>
        <Box padding={2}>
          <LinearProgress />
        </Box>
      </Paper>
    );
  }

  return (
    <Paper elevation={2}>
      <Stack
        padding={2}
        spacing={2}
        component='form'
        autoComplete='off'
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          createSuggestion();
        }}
      >
        <Typography variant='h5' gutterBottom>New Suggestion</Typography>
        {error && <Alert severity='error' variant='outlined'>
          <AlertTitle>{error.title}</AlertTitle>
          {error.message}
        </Alert>}
        <Snackbar
          autoHideDuration={6000}
          open={success !== null && isSuccessOpen}
          onClose={() => setSuccessOpen(false)}>
          <Alert
            severity='success'
            variant='filled'
            elevation={5}
            sx={{ width: '100%' }}
            onClose={() => setSuccessOpen(false)}
          >
            {success}
          </Alert>
        </Snackbar>
        <TextField
          label='Suggestion Word'
          variant='standard'
          helperText='The word to change the syllables for'
          fullWidth
          required
          value={word}
          onChange={(e) => setWord(e.target.value)}
          autoFocus
        />
        <TextField
          label='Description'
          variant='standard'
          helperText='A description of why this word should be changed'
          fullWidth
          multiline
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Stack spacing={2}>
          {syllables.map((syllable, index) => (
            <Stack key={`Syllable${index}`} spacing={2} direction='row' alignItems='center'>
              <TextField
                label={`Syllables (${index + 1})`}
                variant='standard'
                type='number'
                value={Number.isInteger(syllable) ? syllable : ''}
                onChange={(e) => {
                  let intValue: number | null = parseInt(e.target.value, 10);
                  if (!Number.isInteger(intValue) || intValue < 0) {
                    intValue = null;
                  }

                  if (intValue !== syllable) {
                    const newSyllables = [...syllables];
                    newSyllables[index] = intValue;
                    setSyllables(newSyllables);
                  }
                }}
                onKeyPress={(e) => {
                  console.log(e.key);
                  if (e.key === ',') {
                    setSyllables([...syllables, null]);
                  }
                }}
                autoFocus={index > 0}
                fullWidth
                required
              />
              <IconButton
                aria-label='remove syllable'
                onClick={() => {
                  const newSyllables = [...syllables];
                  newSyllables.splice(index, 1);
                  setSyllables(newSyllables);
                }}
              >
                <Delete />
              </IconButton>
            </Stack>
          ))}
          <Stack direction='row'>
            <Button
              variant='outlined'
              color='primary'
              startIcon={<Add />}
              onClick={() => {
                setSyllables([...syllables, null]);
              }}
            >
              Add Syllable
            </Button>
          </Stack>
        </Stack>
        <Button
          variant='contained'
          color='primary'
          type='submit'
        >
          Submit
        </Button>
      </Stack>
    </Paper>
  );
}

function SuggestionCreate(): JSX.Element {
  return (
    <Stack spacing={3}>
      <Typography variant='h1' align='center' gutterBottom>Create a Suggestion</Typography>
      <Typography variant='subtitle1' gutterBottom>
        Create a new suggestion for the syllable count for a word used by HaikuBot.
      </Typography>
      <SuggestionForm />
    </Stack>
  );
}

export default SuggestionCreate;
