import React, { useEffect, useState } from 'react';
import {
  Alert,
  Menu,
  MenuItem,
  IconButton,
  Snackbar,
  CircularProgress,
  Link,
  ListItemIcon,
  Typography,
  Button,
} from '@mui/material';
import {
  AccountCircle,
  Logout,
} from '@mui/icons-material';
import { ErrorResponse, getAuthToken, parseError, setAuthToken, updateCurrentUser } from 'src/util/dashboardAxios';
import { UserModel } from 'src/models/user';
import { AxiosError } from 'axios';

function AccountMenu(): JSX.Element {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UserModel | null>(null);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [hasRequestedUser, setRequestedUser] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);

  async function updateUserState(): Promise<void> {
    try {
      // Load user from the API
      setRequestedUser(true);
      setLoading(true);
      setError(null);
      setShowError(false);
      setMenuAnchor(null);

      const response = await updateCurrentUser();
      setLoading(false);
      setUser(response?.data || null);
    } catch (responseError) {
      setLoading(false);

      const axiosError = responseError as AxiosError;
      if (axiosError && axiosError.response?.status !== 401) {
        setError(parseError(responseError));
        setShowError(true);
      }
    }
  }

  async function logoutUser(): Promise<void> {
    try {
      // Load user from the API
      setLoading(true);
      setError(null);
      setMenuAnchor(null);

      setAuthToken(null);
      window.location.href = '/';
    } catch (responseError) {
      setError(parseError(responseError));
    }
  }

  useEffect(() => {
    if (!hasRequestedUser && getAuthToken()) {
      updateUserState();
    }
  });

  if (isLoading) {
    return (
      <CircularProgress color='inherit' size={24} />
    );
  }

  if (error) {
    return (
      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoHideDuration={5000}
        open={showError}
        onClose={() => setShowError(false)}
      >
        <Alert severity='error' variant='filled' elevation={5}>
          {error.message}
        </Alert>
      </Snackbar>
    );
  }

  const strapiEndpoint = process.env.REACT_APP_STRAPI_ENDPOINT;

  if (user === null) {
    return (
      <Button
        color='inherit'
        component={Link}
        href={`${strapiEndpoint}/api/connect/discord`}
      >
        Login
      </Button>
    );
  }

  return (
    <>
      <IconButton
        aria-label='account of current user'
        aria-haspopup='true'
        onClick={(event) => setMenuAnchor(event.currentTarget)}
        color='inherit'
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id='menu-profile'
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuAnchor !== null}
        onClose={() => setMenuAnchor(null)}
      >
        <MenuItem key='MenuUsername' disabled>{user?.username}</MenuItem>
        <MenuItem key='MenuLogout' onClick={logoutUser}>
          <ListItemIcon><Logout /></ListItemIcon>
          <Typography variant='inherit'>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default AccountMenu;
