import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { axiosClient, setAuthToken, setUserRole } from 'src/util/dashboardAxios';
import { Alert, Box, LinearProgress, Paper, Typography } from '@mui/material';
import { UserConnectCallbackModel } from 'src/models/user';

function DiscordCallback(): JSX.Element {
  const [error, setError] = useState<string | null>(null);
  const [discordAuthToken, setDiscordAuthToken] = useState<string | null>(null);

  const query = new URLSearchParams(useLocation().search);
  const accessToken = query.get('access_token');

  console.log(discordAuthToken);
  setAuthToken(discordAuthToken);

  useEffect(() => {
    async function getAuthToken() {
      try {
        setUserRole(null);
        // Get auth token from Strapi
        const response = await axiosClient.get(`/api/auth/discord/callback?access_token=${accessToken}`);
        const userConnectCallback = response.data as UserConnectCallbackModel;
        setDiscordAuthToken(userConnectCallback.jwt);
      } catch (responseError) {
        const err = responseError as Error;
        setError(err.message);
      }
    }

    getAuthToken();
  });

  if (error) {
    return (
      <Box paddingY={3}>
        <Paper>
          <Box padding={2}>
            <Typography variant='h5' gutterBottom>An error occurred</Typography>
            <Alert severity='error'>{error}</Alert>
          </Box>
        </Paper>
      </Box>
    );
  }

  if (discordAuthToken) {
    window.location.href = '/';
  }

  return (
    <Box paddingY={3}>
      <Paper>
        <Box padding={2}>
          <Typography variant='h5' gutterBottom>Logging in</Typography>
          <LinearProgress />
        </Box>
      </Paper>
    </Box>
  );
}

export default DiscordCallback;
