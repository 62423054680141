import React from 'react';
import { Route } from 'react-router-dom';
import DiscordCallback from './pages/auth/DiscordCallback';

import Home from './pages/Home';

import Suggestions from './pages/suggestions/Suggestions';
import SuggestionsHistory from './pages/suggestions/SuggestionsHistory';
import SuggestionCreate from './pages/suggestions/SuggestionCreate';

import Layout from './components/Layout';

import { Box } from '@mui/material';

function App(): JSX.Element {
  return (
    <Layout>
      <Route exact path='/' component={Home} />

      <Box
        margin='auto'
        maxWidth={1200}
        paddingX={0.5}
        paddingY={1}
        sx={{
          px: {
            xs: 1,
            sm: 2,
            md: 3,
            lg: 6,
          },
          py: {
            xs: 3,
            lg: 6,
          },
        }}
      >
        <Route exact path='/suggestions' component={Suggestions} />
        <Route exact path='/suggestions/history' component={SuggestionsHistory} />
        <Route exact path='/suggestions/create' component={SuggestionCreate} />
        <Route exact path='/auth/discord/callback' component={DiscordCallback} />
      </Box>
    </Layout>
  );
}

export default App;
