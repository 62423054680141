import React, { useState } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import {
  ArrowForward,
  Check,
  Close,
  ThumbDownAlt,
  ThumbDownOffAlt,
  ThumbUpAlt,
  ThumbUpOffAlt,
} from '@mui/icons-material';
import { SuggestionModel } from 'src/models/suggestion';
import { axiosClient, ErrorResponse, getUserRole, parseError } from 'src/util/dashboardAxios';

interface SuggestionCardProps {
  suggestion: SuggestionModel;
  showOldSyllables: boolean;
  onChange?: () => void;
}

interface SuggestionChangeProps {
  suggestion: SuggestionModel;
  onChange?: () => void;
}

interface SuggestionSyllableProps {
  suggestion: SuggestionModel;
  showOldSyllables: boolean;
}

function SuggestionSyllables(props: SuggestionSyllableProps): JSX.Element {
  const { suggestion, showOldSyllables } = props;

  return (
    <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
      {suggestion.attributes.oldSyllables && showOldSyllables &&
        <Typography fontSize={36}>
          {suggestion.attributes.oldSyllables?.join(' or ')}
        </Typography>
      }
      <ArrowForward />
      <Typography fontSize={36}>{suggestion.attributes.syllables?.join(' or ')}</Typography>
    </Stack >
  );
}

function SuggestionVoting(props: SuggestionChangeProps): JSX.Element {
  const { suggestion, onChange } = props;
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  async function deleteVote(voteId: string): Promise<void> {
    try {
      setLoading(true);
      setError(null);
      // Delete existing vote
      await axiosClient.delete(`/api/votes/${voteId}`);
    } catch (responseError) {
      setError(parseError(responseError));
    } finally {
      setLoading(false);

      if (onChange) {
        onChange();
      }
    }
  }

  async function submitVote(voteType: 'up' | 'down', voteId: string | null, suggestionId: string): Promise<void> {
    try {
      setLoading(true);
      setError(null);
      // Check for existing vote
      if (voteId) {
        // Update existing vote
        await axiosClient.put(`/api/votes/${voteId}`, { data: { type: voteType, suggestion: suggestionId } });
      } else {
        // Create new vote
        await axiosClient.post('/api/votes', { data: { type: voteType, suggestion: suggestionId } });
      }
    } catch (responseError) {
      setError(parseError(responseError));
    } finally {
      setLoading(false);

      if (onChange) {
        onChange();
      }
    }
  }

  // Get the vote type
  const voteId = suggestion.attributes.userVote?.id || null;
  const upvoted = voteId && suggestion.attributes.userVote?.type === 'up';
  const downvoted = voteId && suggestion.attributes.userVote?.type === 'down';

  return (
    <Stack spacing={2}>
      {error && <Alert severity='error' variant='outlined'>
        <AlertTitle>{error.title}</AlertTitle>
        {error.message}
      </Alert>}
      <Stack
        direction='row'
        justifyContent='center'
        spacing={2}
        divider={<Divider orientation='vertical' flexItem />}
      >
        <Button
          disableElevation
          disabled={isLoading}
          color='success'
          variant={upvoted ? 'contained' : 'outlined'}
          aria-label='vote up'
          startIcon={upvoted ? <ThumbUpAlt /> : <ThumbUpOffAlt />}
          onClick={upvoted ? () => deleteVote(voteId) : () => submitVote('up', voteId, suggestion.id)}
        >
          {suggestion.attributes.upvotes}
        </Button>
        <Button
          disableElevation
          disabled={isLoading}
          color='error'
          variant={downvoted ? 'contained' : 'outlined'}
          aria-label='vote down'
          startIcon={downvoted ? <ThumbDownAlt /> : <ThumbDownOffAlt />}
          onClick={downvoted ? () => deleteVote(voteId) : () => submitVote('down', voteId, suggestion.id)}
        >
          {suggestion.attributes.downvotes}
        </Button>
      </Stack>
    </Stack>
  );
}

function SuggestionApproval(props: SuggestionChangeProps): JSX.Element {
  const { suggestion, onChange } = props;
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  async function declineSuggestion(suggestionId: string): Promise<void> {
    try {
      setLoading(true);
      setError(null);
      await axiosClient.delete(`/api/suggestions/${suggestionId}`);
    } catch (responseError) {
      setError(parseError(responseError));
    } finally {
      setLoading(false);
      if (onChange) {
        onChange();
      }
    }
  }

  async function approveSuggestion(suggestionId: string): Promise<void> {
    try {
      setLoading(true);
      setError(null);
      await axiosClient.post(`/api/suggestions/approve/${suggestionId}`, {
        data: {
          word: suggestion.attributes.word,
          syllables: suggestion.attributes.syllables.join(','),
        },
      });
    } catch (responseError) {
      setError(parseError(responseError));
    } finally {
      setLoading(false);
      if (onChange) {
        onChange();
      }
    }
  }

  return (
    <Stack spacing={2}>
      <Stack
        direction='row'
        justifyContent='center'
        spacing={2}
        divider={<Divider orientation='vertical' flexItem />}
      >
        <Button
          disabled={isLoading}
          color='success'
          variant='outlined'
          aria-label='approve'
          startIcon={<Check />}
          onClick={() => approveSuggestion(suggestion.id)}
        >
          Approve
        </Button>
        <Button
          disabled={isLoading}
          color='error'
          variant='outlined'
          aria-label='decline'
          startIcon={<Close />}
          onClick={() => declineSuggestion(suggestion.id)}
        >
          Decline
        </Button>
      </Stack>
      {error && <Alert severity='error' variant='outlined'>
        <AlertTitle>{error.title}</AlertTitle>
        {error.message}
      </Alert>}
    </Stack>
  );
}

function SuggestionCard(props: SuggestionCardProps): JSX.Element {
  const { suggestion, showOldSyllables, onChange } = props;
  // Convert word to sentence case
  const title = suggestion.attributes.word.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });

  const userRole = getUserRole();

  return (
    <Paper elevation={2}>
      <Stack padding={2} spacing={2}>
        <Box>
          <Stack direction='row'>
            <Typography variant='h5' gutterBottom>{title}</Typography>
            <Box flexGrow={1} />
            {userRole === 'administrator' && !suggestion.attributes.isApproved &&
              <SuggestionApproval suggestion={suggestion} onChange={onChange} />
            }
          </Stack>
          {suggestion.attributes.description &&
            <Typography variant='body2' gutterBottom>
              {suggestion.attributes.description}
            </Typography>
          }
          <SuggestionSyllables suggestion={suggestion} showOldSyllables={showOldSyllables} />
        </Box>
        {!suggestion.attributes.isApproved &&
          <SuggestionVoting suggestion={suggestion} onChange={onChange} />
        }
      </Stack>
    </Paper>
  );
}

export default SuggestionCard;
