import React, { useEffect, useState } from 'react';
import { axiosClient, ErrorResponse, parseError } from 'src/util/dashboardAxios';
import { Alert, AlertTitle, Box, duration, easing, LinearProgress, Pagination, Paper, Stack } from '@mui/material';
import SuggestionCard from './SuggestionCard';

import FlipMove from 'react-flip-move';

import { SuggestionModel } from 'src/models/suggestion';
import { StrapiMeta, StrapiResponse } from 'src/models/strapi';

import qs from 'qs';

export interface SuggestionListProps {
  searchQuery: string | null;
  viewHistorical: boolean;
  maxResults: number;
}

function SuggestionList(props: SuggestionListProps): JSX.Element {
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [suggestions, setSuggestions] = useState<SuggestionModel[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<StrapiMeta | null>(null);

  async function updateSuggestions() {
    try {
      setError(null);
      // Create get query params
      const query = qs.stringify({
        sort: ['rating:desc'],
        filters: { word: { $containsi: props.searchQuery }, isApproved: props.viewHistorical },
        pagination: {
          page: page,
          pageSize: props.maxResults,
        },
      }, { encodeValuesOnly: true });

      const response = await axiosClient.get<StrapiResponse<SuggestionModel[]>>(`/api/suggestions?${query}`);
      setSuggestions(response.data.data);
      setMeta(response.data.meta);
    } catch (responseError) {
      setError(parseError(responseError));
      setSuggestions([]);
      setMeta(null);
    }
  }

  useEffect(() => {
    updateSuggestions();
  }, [props.searchQuery, page]);

  if (error) {
    return (
      <Paper>
        <Box padding={2}>
          <Alert severity='error' variant='outlined'>
            <AlertTitle>Error getting suggestions</AlertTitle>
            {error.message}
          </Alert>
        </Box>
      </Paper>
    );
  }

  if (suggestions !== null) {
    return (
      <FlipMove duration={duration.short} easing={easing.easeInOut}>
        {suggestions?.length > 0 && meta && meta?.pagination.pageCount > 1 &&
          <Stack key='SuggestionsPaginationTop' paddingBottom={3} alignItems='center'>
            <Pagination
              color='primary'
              count={meta?.pagination.pageCount}
              page={page}
              onChange={(e, value) => setPage(value)}
            />
          </Stack>
        }
        {suggestions?.length === 0 &&
          <Stack key='SuggestionsEmptyAlert' paddingBottom={3}>
            <Paper>
              <Box padding={2}>
                {props.viewHistorical ?
                  <Alert severity='info' variant='outlined'>
                    There are no approved suggestions.
                  </Alert>
                  :
                  <Alert severity='info' variant='outlined'>
                    There are no suggestions right now, why not create one?
                  </Alert>
                }
              </Box>
            </Paper>
          </Stack>
        }
        {suggestions?.map((suggestion) => (
          <Stack key={`Suggestion${suggestion.id}`} paddingBottom={3}>
            <SuggestionCard
              suggestion={suggestion}
              showOldSyllables={!props.viewHistorical}
              onChange={() => updateSuggestions()}
            />
          </Stack>
        ))}
        {suggestions?.length > 0 && meta && meta?.pagination.pageCount > 1 &&
          <Stack key='SuggestionsPaginationBottom' paddingBottom={3} alignItems='center'>
            <Pagination
              color='primary'
              count={meta?.pagination.pageCount}
              page={page}
              onChange={(e, value) => setPage(value)}
            />
          </Stack>
        }
      </FlipMove>
    );
  }

  return (
    <Paper>
      <Box padding={2}>
        <LinearProgress />
      </Box>
    </Paper>
  );
}

SuggestionList.defaultProps = {
  viewHistorical: false,
  maxResults: 10,
};

export default SuggestionList;
