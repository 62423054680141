import React, { useState } from 'react';
import { Button, IconButton, InputBase, Paper, Stack } from '@mui/material';
import { Search } from '@mui/icons-material';

interface SuggestionSearchBarProps {
  onSearch: (query: string) => void;
}

function SuggestionSearchBar(props: SuggestionSearchBarProps): JSX.Element {
  const [hasFocus, setFocus] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  return (
    <Stack direction='row' spacing={1}>
      <Paper sx={{ flex: 1 }} elevation={hasFocus ? 2 : 1}>
        <Stack direction='row' padding={0.5} spacing={0.5}>
          <IconButton size='small'>
            <Search />
          </IconButton>
          <InputBase
            fullWidth
            placeholder='Search'
            value={query}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={(e) => {
              setQuery(e.target.value);
              // Perform search
              const tempQuery = e.target.value;
              setTimeout(() => {
                if (tempQuery === e.target.value) {
                  props.onSearch(tempQuery);
                }
              }, 250);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                props.onSearch(query);
              }
            }}
          />
        </Stack>
      </Paper>
      <Button variant='contained' color='primary' onClick={() => props.onSearch(query)}>Search</Button>
    </Stack>
  );
}

export default SuggestionSearchBar;
