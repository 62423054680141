import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

function Home(): JSX.Element {
  return (
    <Stack spacing={3}>
      <Box
        position='relative'
        paddingX={2}
        paddingY={12}
        style={{
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
          textShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
          overflow: 'hidden',
        }}
      >
        <Box
          position='absolute'
          left='0'
          top='0'
          right='0'
          bottom='0'
          zIndex={-1}
          style={{
            backgroundImage: 'url("/img/banner-background.jpg")',
            backgroundSize: 'cover',
            filter: 'blur(16px)',
            margin: -32,
          }}
        />
        <Stack spacing={3}>
          <Typography
            variant='h1'
            align='center'
            color='white'
          >
            HaikuBot Dashboard
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
}

export default Home;
