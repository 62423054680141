import React, { Component } from 'react';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';

import {
  History,
  Home,
  Menu,
  ThumbsUpDown,
} from '@mui/icons-material';
import styled from '@emotion/styled';

import { getUserRole } from 'src/util/dashboardAxios';
import AccountMenu from './auth/AccountMenu';

interface NavMenuProps {
  children: React.ReactNode;
  useTemporaryDrawer: boolean;
  theme: Theme;
}

interface NavMenuState {
  isOpen: boolean;
}

interface DrawerCategory {
  validRoles?: string[];
  title?: string;
  items: LinkItem[];
}

interface LinkItem {
  label: string;
  href: string;
  icon?: React.ReactNode;
}

const drawerData: DrawerCategory[] = [
  {
    items: [{ label: 'Home', href: '/', icon: <Home /> }],
  },
  {
    title: 'Suggestions',
    items: [
      {
        label: 'Suggestions',
        href: '/suggestions',
        icon: <ThumbsUpDown aria-label='suggestions' />,
      },
      {
        label: 'History',
        href: '/suggestions/history',
        icon: <History aria-label='suggestions history' />,
      },
    ],
  },
];

const DrawerList = styled(List)<{ component?: React.ElementType }>(({ theme }) => {
  const styledTheme = theme as Theme;

  return {
    '& .MuiListItemButton-root': {
      borderRadius: styledTheme.shape.borderRadius,
      marginLeft: 8,
      marginRight: 8,
      marginTop: 4,
      marginBottom: 4,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .MuiListItemButton-root.Mui-selected': {
      color: styledTheme.palette.primary.main,
    },
    '& .MuiListItemButton-root.Mui-selected .MuiListItemIcon-root': {
      color: styledTheme.palette.primary.main,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0,
      marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  };
});

export default class NavMenu extends Component<NavMenuProps, NavMenuState> {
  constructor(props: NavMenuProps) {
    super(props);
    this.state = { isOpen: false };
  }

  render(): React.ReactNode {
    const { children, useTemporaryDrawer, theme } = this.props;
    const { isOpen } = this.state;

    const userRole = getUserRole();
    const accessibleDrawerData = drawerData.filter((category) => category.validRoles === undefined
      || (category.validRoles && userRole !== null && category.validRoles.includes(userRole)));

    return (
      <Stack direction='row'>
        <AppBar position='fixed' sx={{ zIndex: (theme?.zIndex.drawer || 0) + 1 }}>
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              sx={{ display: useTemporaryDrawer ? 'inherit' : 'none' }}
              onClick={() => this.setState({ isOpen: !isOpen })}
            >
              <Menu />
            </IconButton>
            <Typography
              variant='h6'
              component='div'
              noWrap
              sx={{ display: { xs: 'none', sm: 'inherit' } }}
            >
              <Link href='/' color='inherit' style={{ textDecoration: 'none' }}>HaikuBot Dashboard</Link>
            </Typography>
            <Box flexGrow={1} />
            <AccountMenu />
          </Toolbar>
        </AppBar>
        <Drawer
          variant={useTemporaryDrawer ? 'temporary' : 'permanent'}
          open={isOpen}
          sx={{
            width: 240,
            flexShrink: 0,
            '& .MuiDrawer-paper': { width: 240, boxSizing: 'border-box' },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto' }}>
            <DrawerList component='nav'>
              {accessibleDrawerData.map((category) => (
                <Box key={category.title || 'root'}>
                  {category.title && <ListSubheader component='div'>{category.title}</ListSubheader>}
                  {category.items.map((item) => (
                    <ListItemButton
                      key={item.label}
                      component='a'
                      href={item.href}
                      selected={window.location.pathname === item.href}
                    >
                      {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                      <ListItemText primary={item.label} />
                    </ListItemButton>
                  ))}
                </Box>
              ))}
            </DrawerList>
          </Box>
        </Drawer>
        <Box component='main' sx={{ flexGrow: 1 }}>
          <Toolbar />
          {children}
        </Box>
      </Stack>
    );
  }
}
