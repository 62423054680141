import React from 'react';
import { ReactElement } from 'react';
import { CssBaseline, PaletteMode, useMediaQuery } from '@mui/material';
import { createTheme, PaletteOptions, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import NavMenu from './NavMenu';

interface LayoutProps {
  children: ReactElement[];
}

const getPalette = (mode: PaletteMode): PaletteOptions => ({
  mode,
  primary: {
    main: '#673ab7',
    light: '#9a67ea',
    dark: '#320b86',
  },
  secondary: {
    main: '#ffcc4d',
    light: '#ffff7f',
    dark: '#c89b14',
  },
  ...(mode === 'light' ? {
    background: {
      default: '#f0f0f0',
    },
  } : {
    error: {
      main: '#e57373',
      light: '#ffa4a2',
      dark: '#af4448',
    },
  }),
});

function Layout({ children }: LayoutProps): JSX.Element {
  let theme = createTheme({
    palette: getPalette('light'),
    typography: {
      fontFamily: [
        '"Nunito"',
        'Helvetica',
        'Arial',
        'sans-serif',
      ].join(','),
      h1: {
        fontSize: '5rem',
        lineHeight: 1,
        letterSpacing: 0,
        fontFamily: [
          '"Quicksand"',
          'Helvetica',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      subtitle1: { opacity: 0.9 },
      subtitle2: { opacity: 0.9 },
      body2: { opacity: 0.9, fontSize: '0.95rem' },
    },
    shape: {
      borderRadius: 21,
    },
  });

  theme = responsiveFontSizes(theme);

  const isBreakpointSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavMenu useTemporaryDrawer={isBreakpointSmall} theme={theme}>
        {children}
      </NavMenu>
    </ThemeProvider>
  );
}

export default Layout;
