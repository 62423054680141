import axios, { AxiosResponse } from 'axios';
import config from 'src/config.json';
import { UserModel } from 'src/models/user';

export interface ErrorResponse {
  title: string;
  message: string;
}

/**
 * Set the access token for the current user.
 * @param authToken The new access token for the current user.
 */
export function setAuthToken(authToken: string | null): void {
  if (localStorage) {
    if (authToken) {
      localStorage.setItem(config.storage.accessToken, authToken);
    } else {
      localStorage.removeItem(config.storage.accessToken);
    }
  } else {
    throw new Error('Could not save access token');
  }
}

/**
 * Get the access token for the current user, or null if the access token does not exist.
 * @returns The access token for the current user, or null if no access token can be found.
 */
export function getAuthToken(): string | null {
  if (localStorage) {
    return localStorage.getItem(config.storage.accessToken);
  } else {
    throw new Error('Could not read access token');
  }
}

/**
 * Set the role for the current user.
 * @param role The new role for the current user.
 */
export function setUserRole(role: string | null): void {
  if (localStorage) {
    if (role) {
      localStorage.setItem(config.storage.userRole, role);
    } else {
      localStorage.removeItem(config.storage.userRole);
    }
  } else {
    throw new Error('Could not save user role');
  }
}

/**
 * Get the role for the current user, or null if the role does not exist.
 * @returns The role for the current user, or null if no role can be found.
 */
export function getUserRole(): string | null {
  if (localStorage) {
    return localStorage.getItem(config.storage.userRole);
  } else {
    throw new Error('Could not read user role');
  }
}

export function parseError(error: unknown): ErrorResponse {
  const errorRecord = error as Record<string, unknown>;
  if (errorRecord) {
    const errorResponse = errorRecord.response as Record<string, unknown>;
    if (errorResponse) {
      const errorData = errorResponse.data as Record<string, string>;
      if (errorData.error && errorData.message) {
        return { title: errorData.error, message: errorData.message };
      } else if (errorData.error) {
        const errorRequest = errorData.error as unknown as Record<string, string>;
        return { title: 'Error', message: errorRequest.message };
      }

      return { title: 'Error', message: errorResponse.data as string };
    }
  }

  const errorObject = error as Error;
  if (errorObject) {
    return { title: errorObject.name, message: errorObject.message };
  }

  return { title: 'Error', message: 'An unknown error occurred' };
}

// Setup axios client
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_STRAPI_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use((request) => {
  // Attach access token
  const authToken = getAuthToken();
  if (authToken && request.headers) {
    request.headers.Authorization = `Bearer ${authToken}`;
  }

  return request;
});

export async function updateCurrentUser(): Promise<AxiosResponse<UserModel> | null> {
  try {
    const response = await axiosClient.get<UserModel>('/api/users/me');
    setUserRole(response.data.role.type);
    return response;
  } catch (responseError) {
    setUserRole(null);
    throw responseError;
  }
}

export { axiosClient };
