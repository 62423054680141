import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import SuggestionList from 'src/components/suggestions/SuggestionList';
import SuggestionSearchBar from 'src/components/suggestions/SuggestionSearchBar';

function Suggestions(): JSX.Element {
  const [searchQuery, setSearchQuery] = useState<string>('');

  return (
    <Stack spacing={3}>
      <Typography variant='h1' align='center' gutterBottom>History</Typography>
      <Typography variant='subtitle1' gutterBottom>
        View previous suggestions for HaikuBot that have been approved.
      </Typography>
      <SuggestionSearchBar onSearch={(query) => setSearchQuery(query)} />
      <SuggestionList searchQuery={searchQuery} viewHistorical={true} />
    </Stack>
  );
}

export default Suggestions;
