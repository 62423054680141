import React, { useState } from 'react';
import { Fab, Stack, Typography } from '@mui/material';
import { Create } from '@mui/icons-material';
import SuggestionList from 'src/components/suggestions/SuggestionList';
import SuggestionSearchBar from 'src/components/suggestions/SuggestionSearchBar';

function Suggestions(): JSX.Element {
  const [searchQuery, setSearchQuery] = useState<string>('');

  return (
    <Stack spacing={3}>
      <Typography variant='h1' align='center' gutterBottom>Suggestions</Typography>
      <Typography variant='subtitle1' gutterBottom>
        Vote on suggestions for syllable fixes for HaikuBot. Either cast your vote on other people&apos;s suggestions,
        or add your own if it doesn&apos;t exist yet.
      </Typography>
      <SuggestionSearchBar onSearch={(query) => setSearchQuery(query)} />
      <SuggestionList searchQuery={searchQuery} />
      <Fab
        href='/suggestions/create'
        variant='extended'
        color='primary'
        aria-label='submit new suggestion'
        sx={{ position: 'fixed', right: 24, bottom: 24 }}
      >
        <Create sx={{ mr: 1 }} />
        Submit Suggestion
      </Fab>
    </Stack>
  );
}

export default Suggestions;
